<template>
  <span class="tw-inline-flex tw-items-center">
    <img src="./../assets/kod-logo.svg" alt="Logo Koderia" />
    <span class="tw-font-extrabold tw-text-2xl tw-ml-3">
      {{ title }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
