<template>
  <k-layout>
    <div class="tw-bg-white">
      <aside class="aside tw-bg-gray-900" ref="aside">
        <img src="./assets/character.svg" alt="Character" class="character" />
      </aside>
      <div>
        <v-container class="justify-center d-flex align-center pa-4 tw-min-h-screen tw-h-full">
          <v-layout column class="wrapper">
            <v-row class="content">
              <v-col>
                <form @submit.prevent="submit" ref="form">
                  <k-product-title title="Newsletter" />
                  <h2 class="tw-mt-4 tw-heading-2">{{ $t('newsletter.subtitles.personalized_jobs') }}</h2>
                  <p class="mt-6">
                    {{ $t('newsletter.descriptions.koderia_newsletter') }}
                  </p>
                  <v-row class="mt-10">
                    <v-col cols="12" md="6">
                      <k-input
                        :title="$t('global.inputs.first_name')"
                        v-model="name"
                        :invalid="submitted && !isValidField('name')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <k-input
                        :title="$t('global.inputs.email')"
                        v-model="email"
                        :invalid="submitted && !isValidField('email')"
                      />
                    </v-col>
                  </v-row>
                  <k-select
                    v-model="profession"
                    :search-input.sync="profession"
                    :items="allProfessions"
                    :title="$t('global.inputs.profession')"
                    multiple
                    class="mt-1"
                    :invalid="submitted && !isValidField('profession')"
                    item-value="value"
                    item-name="name"
                  />
                  <k-autocomplete-technologies
                    v-model="technologies"
                    :items="allTechnologies"
                    :title="$t('global.inputs.technology')"
                    :show-limit="3"
                    class="mt-4"
                    :invalid="submitted && !isValidField('technologies')"
                  />
                  <k-gdpr
                    class="mt-6"
                    v-model="gdpr"
                    :showGdrp="false"
                    :invalidNewsletter="submitted && !isValidField('gdpr')"
                    :newsletterTooltip="$t('gdpr.by_checking_check_box_to_agree_koderia_will_contact_me')"
                  />

                  <k-button class="mt-8 mb-16 tw-w-full" type="submit" width="135" :success="success">
                    {{ $t('auth.actions.sign_in_simple') }}
                  </k-button>
                </form>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </div>
    </div>
  </k-layout>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/functions'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      name: null,
      email: null,
      profession: null,
      technologies: [],
      gdpr: null,
      errorMessage: null,

      success: false,
      submitted: false,
      isFormValid: true,
      formState: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize, false)
      this.$on('hook:beforeDestroy', () => {
        window.removeEventListener('resize', this.onResize, false)
      })

      this.onResize()
    })
  },
  computed: {
    user() {
      return this.$store.state.AUTH.user
    },
    ...mapGetters('ENUMS', {
      allProfessions: 'allStacksNames',
      allTechnologies: 'allTechnologiesNames'
    })
  },
  watch: {
    user: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$router.push({ name: 'profile', query: { tab: 'emailove-nastavenia' } })
        }
      }
    }
  },
  methods: {
    async submit() {
      this.submitted = true

      const fields = ['name', 'email', 'profession', 'technologies', 'gdpr']

      this.isFormValid = fields.map(field => !!this.isValidField(field)).every(Boolean)

      if (this.isFormValid) {
        let data = {}
        fields.forEach(field => {
          data[field] = this[field]
        })

        firebase.functions().httpsCallable('subscribeMailchimpUser')({
          ...data
        })

        this.success = true

        window.location.hash = '#newsletter-hotovo'
        setTimeout(() => {
          this.$router.push({ name: 'Home', hash: '#newsletter-hotovo' })
        }, 2500)
      }
    },
    isValidField(fieldName) {
      switch (fieldName) {
        case 'email':
          return typeof this.email === 'string' && /(.+)@(.+){2,}\.(.+){2,}/.test(this.email)
        case 'name':
          return typeof this.name === 'string' && String(this.name).trim().length > 0
        case 'profession':
          return Array.isArray(this.profession) && this.profession.length > 0
        case 'technologies':
          return Array.isArray(this.technologies) && this.technologies.length > 0
        case 'gdpr':
          return this.gdpr && this.gdpr.newsletterValue === true
      }
    },
    onResize() {
      const { aside, form } = this.$refs
      if (aside && form) {
        if (form.offsetLeft - 240 <= 225) {
          aside.style.width = '14rem'
        } else {
          aside.style.width = `${ ((form.offsetLeft || 0) - 240) / 16 }rem`
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.wrapper {
  width: 100%;
  max-width: 30rem;
}

.aside {
  @include -lg {
    display: none;
  }

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;

  .character {
    width: 27rem;
    position: absolute;
    bottom: 0;
    right: -4rem;
  }
}
</style>
